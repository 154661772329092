*:focus-visible,
*:focus {
    outline: unset !important;
    box-shadow: unset !important;
}
*:not(.flatpickr-input)[readonly] {
    background-color: rgba(216, 216, 216, 0.5) !important;
}

.react-datepicker__day--today {
    background-color: yellow !important;
    border-radius: 5px;
    color: black;
    font-weight: bold;
}
.primary-toggle.react-toggle--checked .react-toggle-track {
    background-color: #6366f1 !important;
}
.rbc-toolbar button{
    color: #6366f1 !important;
    font-weight: 500 !important;
}
.rbc-toolbar .rbc-toolbar-label{
    color: #6366f1 !important;
    font-weight: 500 !important;
}
.react-toggle--focus .react-toggle-thumb{
    box-shadow: 0px 0px 0px 0px !important;
}
.react-toggle-track {
    width: 45px;
}
.react-toggle--checked .react-toggle-thumb{
    left : 22px !important;
}